export const loginForm = [
  {
    key: "name",
    type: "text",
    name: "name",
    label: "Name",
    placeholder: "Name",
    errorMessage: "name must be without number or special character",
    pattern: "[A-Za-z' ']+",
  },
  {
    key:"surNname",
    type:"text",
    name:"surNname",
    label:"Surname",
    placeholder:"Surname",
    errorMessage:"surname must be without number or special character",
    pattern:"[A-Za-z' ']+",
  },
  {
    key: "email",
    type: "email",
    name: "email",
    label: "Email",
    placeholder: "abc@gmail.com",
    errorMessage: "It should be a valid email address!",
    pattern:`[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$`,
  },
  {
    key: "age",
    type: "text",
    name: "age",
    label: "Age",
    placeholder: "Age",
    errorMessage: "age must be greater than 0",
    pattern: "^[' '1-9][0-9' ']*$",
  },
  {
    key: "favoriteColor",
    type: "text",
    name: "favoriteColor",
    label: "Favorite Color",
    placeholder: "Favorite Color",
    errorMessage: "must be a valid color name",
    pattern: "[A-Za-z' ']+",
  },
];
